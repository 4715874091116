import React from "react"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import clsx from "clsx"
import Img from "./Img"
import * as styles from "./banner.module.scss"
import { handleClickBanner } from "../../../helpers/WatcherDL"

import { TypeBannerPropsWithElements } from "../helpers"
import { useObserver } from "../../../hooks"

const titleMob = (
  <>
    Рассрочка от 10 мес.
    <br />
    по карте Халва
  </>
)
const title = (
  <>
    Рассрочка от 10 месяцев
    <br />
    по карте Халва
  </>
)

const description = (
  <>
    — Кэшбэк до 10 %
    <br />— Обслуживание 0 ₽
  </>
)

export function Banner({ buttonText = "Оформить карту", orderNum }: TypeBannerPropsWithElements) {
  const isVisible = useObserver(["formPkw", "calculator", "bottomCta"])
  const isMob = useMediaQuery("(max-width:595.5px)")
  return (
    <>
      <section data-exclude={orderNum}>
        <Container className={styles.container}>
          <div className={styles.secondContainer}>
            <Img alt="bg image" className={styles.img} />
            <div className={styles.text}>
              <h1>{isMob ? titleMob : title}</h1>
              <div>{description}</div>
            </div>
            <Button className={styles.btn} onClick={() => handleClickBanner("click_cta")}>
              {buttonText}
            </Button>
          </div>
        </Container>
      </section>
      <div className={clsx(styles.btnBlock, { [styles.hideMobBlock]: isVisible })}>
        <Container>
          <Button onClick={() => handleClickBanner("sticky")} className={styles.mobBtn}>
            {buttonText}
          </Button>
        </Container>
      </div>
    </>
  )
}
